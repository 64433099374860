import React from "react";
import Training from "../../images/trainingCard.svg";
import FNews from "../../images/fakeNewsCard.svg";
import Talks from "../../images/talksCard.svg";
import Team from "../../images/teamCard.svg"
import SpecialP from "../../images/specialProjectsCard.svg"
import Meditation from "../../images/meditationCard.svg"
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesButton,
  TopPurpleBanner,
  BottomPurpleBanner
} from "./ServicesElements";

const Services = ({ isFilterActive }) => {
  return (
    <ServicesContainer isFilterActive={isFilterActive} id="services">
      <TopPurpleBanner><ServicesH1>HOW</ServicesH1></TopPurpleBanner>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Talks} alt="Illustration of three people holding square speech bubbles."/>
          <ServicesH2>Training</ServicesH2>
          <ServicesButton to="/training">Lean More</ServicesButton>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={FNews} alt="Illustration of a hand holding a phone taking a selfie."/>
          <ServicesH2>Fake News</ServicesH2>
          <ServicesButton to="/fakenews">Lean More</ServicesButton>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Training} alt="Illustration of two students at desks with tutor at a white board."/>
          <ServicesH2>Talks</ServicesH2>
          <ServicesButton to="/talks">Lean More</ServicesButton>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Team} alt="Illustration of three people as with laptops having a discussion."/>
          <ServicesH2>Teams</ServicesH2>
          <ServicesButton to="/teams">Lean More</ServicesButton>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={SpecialP} alt="Illustration of person looking at three information cards in the air."/>
          <ServicesH2>Special Projects</ServicesH2>
          <ServicesButton to="/projects">Lean More</ServicesButton>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Meditation} alt="Illustration of person holding a phone with a confirmation tick on the screen."/>
          <ServicesH2 >Meditation</ServicesH2>
          <ServicesButton to="/meditation">Lean More</ServicesButton>
        </ServicesCard>
      </ServicesWrapper>
      <BottomPurpleBanner />
    </ServicesContainer>
  );
};

export default Services;
